import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.handleClick && $options.handleClick(...args))),
    "data-type": $props.type,
    class: "bg-discord-400 text-white m-1 text-3xl w-8 h-8 inline-flex justify-center items-center rounded-full"
  }, _toDisplayString($props.type), 9, _hoisted_1))
}