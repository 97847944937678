import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-409a76b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "toolbar",
  class: "h-screen bg-discord-500-a backdrop-blur-sm fixed top-0 left-0 flex items-center justify-center flex-col"
}
const _hoisted_2 = { class: "flex justify-center flex-wrap" }
const _hoisted_3 = { class: "flex flex-wrap justify-center" }
const _hoisted_4 = { class: "rounded-lg border-2 m-1 p-1 flex justify-center flex-wrap bg-discord-200" }
const _hoisted_5 = { class: "flex justify-center flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TypeButton = _resolveComponent("TypeButton")!
  const _component_SetSizeButton = _resolveComponent("SetSizeButton")!
  const _component_NewButton = _resolveComponent("NewButton")!
  const _component_DividerElement = _resolveComponent("DividerElement")!
  const _component_ToolButton = _resolveComponent("ToolButton")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_ColorButton = _resolveComponent("ColorButton")!
  const _component_InaccurateEmojiButton = _resolveComponent("InaccurateEmojiButton")!
  const _component_CopyButton = _resolveComponent("CopyButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TypeButton),
      _createVNode(_component_SetSizeButton),
      _createVNode(_component_NewButton)
    ]),
    _createVNode(_component_DividerElement),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ToolButton, {
        icon: "pencil",
        def: ""
      }),
      _createVNode(_component_ToolButton, { icon: "brush" }),
      _createVNode(_component_ToolButton, { icon: "bucket" }),
      _createVNode(_component_ToggleButton, { icon: "grid" })
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.emojiColors, (color, emoji) => {
        return (_openBlock(), _createBlock(_component_ColorButton, {
          key: emoji,
          color: color,
          def: emoji === $data.Emoji.Red,
          "data-inaccurate": $data.inaccurateEmojis.includes(emoji)
        }, null, 8, ["color", "def", "data-inaccurate"]))
      }), 128))
    ]),
    _createVNode(_component_InaccurateEmojiButton),
    _createVNode(_component_DividerElement),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_CopyButton, { text: "Copy" }),
      _createVNode(_component_CopyButton, { text: "Export" }),
      _createVNode(_component_CopyButton, { text: "Share" })
    ])
  ]))
}