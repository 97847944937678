import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfd78e02"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-color"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([{ 'selected': $props.def }, "colorButton selectable hover:brightness-125 rounded-lg w-8 h-8 inline-flex align-center p-2 cursor-pointer"]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.handleClick && $options.handleClick(...args))),
    "data-color": $props.color,
    style: _normalizeStyle({backgroundColor: $props.color})
  }, null, 14, _hoisted_1))
}