import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.handleClick && $options.handleClick(...args))),
      class: "toolButton selectable border-2 bg-discord-500 text-white hover:bg-discord-200 rounded-lg m-1 inline-flex align-center p-2 cursor-pointer"
    }, " Show inaccurate emojis "),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.helpEmojis && $options.helpEmojis(...args))),
      class: "border-2 bg-discord-500 text-white rounded-full w-7 h-7 flex justify-center items-center"
    }, " ? ")
  ]))
}