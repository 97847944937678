import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([{ 'toggled': $props.def }, "toolButton selectable border-2 bg-discord-500 hover:bg-discord-200 rounded-lg w-16 h-16 m-1 inline-flex p-2 cursor-pointer justify-center items-center"]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.handleClick && $options.handleClick(...args)))
  }, [
    _createElementVNode("img", {
      src: '../icons/' + $props.icon + '.svg',
      class: "color-white pointer-events-none w-12"
    }, null, 8, _hoisted_1)
  ], 2))
}